import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Pusher from 'pusher-js';
import axios from 'axios';
import { ArrowPathIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { Button } from '@material-tailwind/react';
import { setMessages } from '../features/messagesSlice';

axios.defaults.withCredentials = true;


const Chat = () => {
    const userId = useSelector((state) => state.userId.value);

    const messages = useSelector((state) => state.messages.value);

    const dispatch = useDispatch();

    const authentication = useSelector((state) => state.authentication.value);
    const firstBanner = JSON.parse(localStorage.getItem('firstBanner'));
    const secondBanner = JSON.parse(localStorage.getItem('secondBanner'));
    const enableBanner = JSON.parse(localStorage.getItem('enableBanner'));



    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const [websocketsDomain, setWebsocketsDomain] = useState(window.WEBSOCKETS_DOMAIN);
    const [websocketsConnection, setWebsocketsConnection] = useState(window.WEBSOCKETS_CONNECTION);
    const [pusherAppKey, setPusherAppKey] = useState(window.PUSHER_APP_KEY);


    const [message, setMessage] = useState('');
    const [messageProcessing, setMessageProcessing] = useState(false);



    let allMessages = [];


    const successNotify = (message) => {
        toast.success(message, {
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
        });
    }


    const sendMessage = async e => {
        e.preventDefault();
        try {
            setMessageProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/sendMessage/`,
                { userId: userId, message },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            successNotify('Message sent successfully.');
            setMessage(() => '');
            setMessageProcessing(() => false);
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
            } else {
                errorNotify(err.response.data.message);
            }
            setMessageProcessing(() => false);
        }
    }


    useEffect(() => {

        if (websocketsConnection === 'self_hosted') {
            //self hosted websockets
            var pusher = new Pusher(pusherAppKey, {
                broadcaster: 'pusher',
                wsHost: websocketsDomain,
                wsPort: 443,
                forceTLS: true,
                disableStats: true,
                cluster: 'eu',//added this line
                wssPort: 443,
                transports: ['websocket'],
                enabledTransports: ['ws', 'wss'],
            });
        } else if (websocketsConnection === 'local') {
            //local server websockets
            var pusher = new Pusher(pusherAppKey, {
                broadcaster: 'pusher',
                // wsHost: websocketsDomain,
                wsHost: '127.0.0.1',
                wsPort: 6001,
                forceTLS: false,
                disableStats: true,
                cluster: 'eu',//added this line
                wssPort: 6001,
                encrypted: true,
                transports: ['websocket'],
                enabledTransports: ['ws', 'wss'],

            });
        } else {
            // paid pusher configuration
            var pusher = new Pusher(pusherAppKey, {
                cluster: 'eu'
            });
        }


        const fetchData = async () => {

            const MessagesChannel = pusher.subscribe('MessagesChannel');
            MessagesChannel.bind('MessagesEvent', function (data) {

                dispatch(setMessages(data.message))

            })

        }
        fetchData();
    }, []);

    return (
        <>

<div className={' justify-between flex flex-col  h-[320px] pt-0 mt-0 pb-1 '
            + ((enableBanner == false) ? ' md:h-[450px]' : ' md:h-[610px]')}>


                {/* <div className="items-center mt-0 p-0 md:p-2 h-14 md:h-16 bg-gradient-to-r from-[#f7a026] to-[#c83c48] rounded-none  text-xs text-gray-50 w-full">  */}
                {/* <div className="items-center mt-0 p-0 md:p-2 h-14 md:h-16 bg-gradient-to-r from-[#49c92c] to-[#0652dd] rounded-none  text-xs text-gray-50 w-full"> */}
                {/* <div className=" items-center mt-0 p-0 md:p-2 h-14 md:h-16 bg-gradient-to-r from-[#f7a026] to-[#49c92c] rounded-none  text-xs text-gray-50 w-full">  */}
                {/* <div className="items-center mt-0 p-0 md:p-2 h-14 md:h-16 bg-gradient-to-r from-[#eb5ccf] to-[#642269] rounded-none  text-xs text-gray-50 w-full"> */}
                {/* <div className="items-center mt-0 p-0 md:p-2 h-14 md:h-16 bg-gradient-to-r from-[#f7a026] to-[#0652dd] rounded-none  text-xs text-gray-50 w-full"> */}
                {/* <div className="items-center mt-0 p-0 md:p-2 h-14 md:h-16 bg-gradient-to-r from-[#FF4500] to-[#6b1d00] rounded-none  text-xs text-gray-50 w-full"> */}
                <div className="items-center mt-0 p-0 md:p-2 h-14 md:h-16 bg-gradient-to-r from-[#d25c18] to-[#c83c48] rounded-none  text-xs text-gray-50 w-full">


                    <div className='w-full '>
                        <div className="font-bold flex items-center justify-center text-center">
                            <span className='text-[16px]'>Chat</span>
                        </div>
                    </div>
                </div>

                <div id="messages" className="bg-[#181c3a] font-normal rounded flex flex-col-reverse  space-y-2 p-0.5 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch ">

                    {messages.map(message => {
                        return (
                            <div className="text-xs" key={message.id}>

                                {(message.type === 'player') && (message.username !== 'me') &&
                                    <span >
                                        <span className="text-amber-600">{message.username}: </span>
                                        <span className="text-gray-300">{message.message}</span>
                                    </span>
                                }
                                {(message.type === 'player') && (message.username === 'me') &&
                                    <span>
                                        <span className="text-amber-600">{message.username}: </span>
                                        <span className="text-amber-600">{message.message}</span>
                                    </span>
                                }
                                {(message.type === 'admin') &&
                                    <span>
                                        <span className="text-amber-600">{message.username}: </span>
                                        <span className="text-red-400">{message.message}</span>
                                    </span>
                                }

                            </div>

                        )
                    })}


                </div>

                <form onSubmit={sendMessage}>
                    <div className="p-0.5 border-gray-200 pt-2">
                        <div className="relative flex font-normal">
                            <span className="absolute inset-y-0 flex items-center">
                                <button type="button" className="inline-flex items-center justify-center rounded-full h-6 w-6 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                                    <PencilSquareIcon />
                                </button>
                            </span>
                            <input
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                name="message"
                                type="text"
                                placeholder="Write your message!"
                                className="text-xs py-1 w-full focus:outline-none focus:placeholder-gray-400 text-[#111111] placeholder-gray-800 pl-6 bg-gray-200 rounded-md" />
                            <div className="absolute right-0 items-center inset-y-0 flex">


                                {messageProcessing === true &&
                                    <button disabled={messageProcessing ? true : false} onClick={sendMessage} type="submit" className=" text-xs inline-flex items-center justify-center px-1 py-1     text-gray-50 rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">
                                        <span className="flex items-center justify-center gap-2 mx-auto">
                                            <ArrowPathIcon className=" animate-spin h-5 w-5 text-white" strokeWidth={2.0} />
                                            Sending...
                                        </span>
                                    </button>
                                }

                                {messageProcessing === false &&
                                    <Button disabled={!message || !authentication ? true : false} onClick={sendMessage} type="submit" className="text-xs inline-flex items-center justify-center px-1 py-1     text-gray-50 rounded bg-[#2e335c] border border-[#413f8d] hover:bg-[#f06514] hover:border-[#f06514] active:border-0 transition duration-700 ease-in-out">
                                        <span className="font-bold">Send</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-3 w-6 transform rotate-90">
                                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                                        </svg>
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>


        </>
    )
}

export default Chat

